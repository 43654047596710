/**
 * All global constants can be defined here
 */

// Static Messages
export const FAILURE_STATUS = "FAILURE";
export const SUCCESS_STATUS = "SUCCESS";

// Static values
export const IST_OFFSET = 330;   // IST offset UTC +5:30

// Generated under Dafidy(staging.dafidy@gmail.com) with name Google maps api key for web app 
// API service name: Maps JavaScript API
export const GOOGLE_API_KEY = 'AIzaSyDrk4VeM3JL3wDvOXfOVzQbTSNm7GITmT8';
export const FIRE_APP = "FIREBOLT";
export const B2B_APP = "B2B_APP";
export const SRMS_DB = "srms";
export const B2B_DB = "b2b";
export const GOOGLE_MAPS_API = "GOOGLE_MAPS_API";