import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import './custom-navbar.component.scss';
import { Layout } from 'antd';
import { isValid, checkIfImageExists } from '../../../utils/utilities';

const { Header } = Layout;

export const CustomNavbar = props => {
  const {
    operatorCode
  } = props;

  const defaultImage = 'https://storage.googleapis.com/customer-website-data/DAFIDY/logo.png';
  const defaultClass = "nav-logo";
  const defaultLogoClass = defaultClass+" small-logo"

  const [imageUrl, setImageUrl] = useState(defaultImage);
  const [logoClass, setLogoClass] = useState(defaultLogoClass);

  useEffect(() => {
    if (isValid(operatorCode)) {
      let tempUrl = `https://storage.googleapis.com/customer-website-data/${operatorCode}/logo.png`;
      checkIfImageExists(tempUrl, (imgResp) => {
        let exists = imgResp.status;
        if (exists) {
          let tempDimensions = imgResp.dimensions;
          let dimensionRatio = tempDimensions.width/tempDimensions.height;
          if (dimensionRatio<2) {
            setLogoClass(defaultLogoClass);
          }
          else if (dimensionRatio<3.5) {
            setLogoClass(defaultClass+" medium-logo");
          }
          else {
            setLogoClass(defaultClass+" large-logo")
          }
          setImageUrl(tempUrl);
        } else {
          setLogoClass(defaultLogoClass);
          setImageUrl(defaultImage);
        }
      });
    }
    else {
      setLogoClass(defaultLogoClass);
      setImageUrl(defaultImage);
    }
  }, [operatorCode])

  return (
      <Header style={{position: 'fixed', zIndex: 2, width: '100%'}}>
        <div className={logoClass} style= {{backgroundImage: `url(${imageUrl})`}}>
        </div>
        <div className="rightMenu">
        </div>
      </Header>
  );
};

CustomNavbar.defaultProps = {

};

CustomNavbar.propTypes = {

};
