/*
  @author sajocmathew
  All utility methods will be defined here
*/
var _ = require('underscore');

/*
  Returns false if data contains atleast requiredLength of characters
*/
export function isUnDefined(data, requiredLength) {
  if (data == null || data == undefined) {
    return true;
  } else {
    var dataJSON = JSON.parse(JSON.stringify(data));
    dataJSON = dataJSON.toString().trim();
    if (dataJSON.length >= requiredLength) {
      return false;
    } else {
      return true;
    }
  }
}

export function getValuesOfJSonObject (jsonObject) {
  return [..._.values(jsonObject)];
}

/*
  Method to return first n elements of given dataList
*/
export function limitArray(dataList, n) {
  var topList = [];
  if (dataList != null && dataList != undefined && n>0) {
    for(var i=0; i<dataList.length; i++) {
      topList.push(dataList[i]);
      if (dataList.length == n) {
        break;
      }
    }
  }
  return topList;
}


export const isValid = (data) => (data != undefined && data != null && data != 0);

export const isValidNumber = (data) => (data != undefined && data != null);

export const isValidText = (data) => (data != undefined && data != null && data != "");

export const isValidDate = (data) => (data != undefined && data != null && data != "" && !isNaN(new Date(data)));

export const isValidArrayWithValues = (array) => (array != undefined && array != null
    && typeof(array) == "object" && array.length > 0);

export const isValidArray = (array) => (array != undefined && array != null
    && typeof(array) == "object");

export const isValidBool = (data) => (data != undefined && data != null && typeof(data) == "boolean");

export function checkIfImageExists(url, callback) {
  const img = new Image();
  img.src = url;
  if (img.complete) {
    callback({status: true, dimensions: {width: img.width, height: img.height}});
  }
  else {
    img.onload = () => {
      callback({status: true, dimensions: {width: img.width, height: img.height}});
    };
    img.onerror = () => {
      callback({status: false, dimensions: {}});
    };
  }
}


// module.exports = {
//   isUnDefined: isUnDefined,
//   limitArray : limitArray,
//   isValid,
//   isValidNumber,
//   isValidText,
//   isValidDate,
//   isValidArrayWithValues,
//   isValidArray,
//   isValidBool,
//   getValuesOfJSonObject,
// }
