import React from 'react';
import * as PropTypes from 'prop-types';
import './vehicle-info.component.scss';
import {FAILURE_STATUS, SUCCESS_STATUS} from "../../utils/constant";
import { isValid } from '../../utils/utilities';

export const VehicleInfo = props => {

  const {trackingDetails} = props;
  let trackingInfo;

  if(trackingDetails !== undefined && trackingDetails.Status === SUCCESS_STATUS) {
    trackingInfo = <div>
      <h5>
        <span className="title">OPERATOR NAME:</span>
        <span className="value">{trackingDetails.payload.schedules.operator_name}</span>
      </h5>
      <h5>
        <span className="title">VEHICLE NO:</span>
        <span className="value">{trackingDetails.payload.tracking.vehicle_no}</span>
      </h5>
      <h5>
        <span className="title">DESCRIPTION:</span>
        <span className="value">{trackingDetails.payload.schedules.description}</span>
      </h5>
      {isValid(trackingDetails.payload.tracking.speed)&&
      <h5>
        <span className="title">Speed:</span>
        <span className="value">{trackingDetails.payload.tracking.speed} KMPH</span>
      </h5>
      }
      {isValid(trackingDetails.payload.tracking.gpsupdatedtime)&&
      <h5>
          <span className="title">LAST UPDATED TIME:</span>
          <span className="value">{trackingDetails.payload.tracking.gpsupdatedtime}</span>
      </h5>
      }
    </div>;
  }

  if(trackingDetails !== undefined && trackingDetails.Status === FAILURE_STATUS) {
    trackingInfo = <h5> {trackingDetails.Message} </h5>;
  }

  return (
    <div className="custom-info">
      {trackingInfo}
    </div>
  );
};

VehicleInfo.defaultProps = {

};

VehicleInfo.propTypes = {
  trackingDetails: PropTypes.object
};
