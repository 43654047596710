/**
 * App level configs are maintained here
 */

export const DEV_ENV = "dev";
export const PROD_ENV = "prod";
export const TEST_ENV = "staging";

export const APP_ENV = PROD_ENV;

export const API_BASE_URL = (APP_ENV === DEV_ENV) ? "http://localhost:8080" : "https://qwikbus.co";
