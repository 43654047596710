import React, { useState } from "react";
import "../assets/styles/main.scss";
import { useSearchParams } from 'react-router-dom';
import {TrackingTab} from "../components/tracking-tab/tracking-tab.component";
import { B2B_APP, SRMS_DB, B2B_DB } from "../utils/constant";
import { isValid } from "../utils/utilities";
import { CustomNavbar } from "../assets/ui-components/custom-navbar/custom-navbar.component";
import { Layout } from "antd";

const { Content } = Layout;

export const HomePage = (props) => {
  let [params, setSearchParams] = useSearchParams();
  var tripId = params.get('tripId');
  if (tripId == null) {
    tripId = params.get('trip_id');
  }
  const pnr = params.get('pnr');
  const app = params.get('app');
  const isValidServer = isValid(app);
  const server = app == B2B_APP?B2B_DB:SRMS_DB;

  const [operatorCode, setOperatorCode] = useState('DAFIDY');


  return (
    <>
      <CustomNavbar operatorCode = {operatorCode}/>
      <Content style={{ minHeight: '588px', marginTop: 64 }}>
        <TrackingTab tripId={tripId} pnr={pnr} server={server} isValidServer ={isValidServer}
          operatorCode = {operatorCode} setOperatorCode = {setOperatorCode}
        />
      </Content>
    </>
  );
};
