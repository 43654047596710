import React, {useEffect, useState, useContext} from 'react';
import './tracking-tab.component.scss';
import {Col, Row, Tabs, Collapse} from 'antd';
import { InfoCircleOutlined, RadarChartOutlined } from '@ant-design/icons'
import {VehicleInfo} from "../vehicle-info/vehicle-info.component";
import LiveMap from "../live-map/live-map.component";
import {getCall, postCall} from "../../utils/network.util";
import {FETCH_TRACKING_DETAILS_URL, FETCH_APP_CONFIG_DATA_BY_KEY_URL} from "../../utils/api-url";
import { FAILURE_STATUS, GOOGLE_MAPS_API, SUCCESS_STATUS } from '../../utils/constant';
import { isValid } from '../../utils/utilities';
import { DeviceContext } from '../device-provider/device-provider';

const { TabPane } = Tabs;

export const TrackingTab = props => {

  const {
    tripId,
    pnr,
    server,
    isValidServer,
    operatorCode,
    setOperatorCode
  } = props;

    const [trackingDetails, updateTrackingDetails] = useState(undefined);
    const [mapApiKey, setMapsApiKey] = useState(null);
    const [activeKey, setActiveKey] = useState("2");

    let fetchPosition = (interval) => {
        getCall(FETCH_TRACKING_DETAILS_URL + `?trip_id=${tripId}&pnr=${pnr}&server=${server}`).then((response) => {
            updateTrackingDetails(response.payload);
          let payload = response.payload;
          if (payload.Status==SUCCESS_STATUS && isValid(payload.payload) && isValid(payload.payload.tracking.operator_code)) {
            let newOperatorCode = payload.payload.tracking.operator_code;
            if (newOperatorCode!=operatorCode) {
              setOperatorCode(newOperatorCode);
            }
          }
          if (payload.Status==SUCCESS_STATUS && payload.payload.showTrackingData==false&& !isValid(payload.payload.tracking.latitude)) {
            setActiveKey("1");
            stopInterval(interval);
          }
          else {
            if (payload.Status==FAILURE_STATUS) {
              stopInterval(interval)
            }
          }

        });
    };

    const onChangeTab = (key) => {
      setActiveKey(key);
    };

    const {isMobile} = useContext(DeviceContext)

    useEffect(()=> {
      if (isValidServer) {
        postCall(FETCH_APP_CONFIG_DATA_BY_KEY_URL, {server: server, appKey: GOOGLE_MAPS_API}).then((response) => {
          if (response.status==="SUCCESS"&&response.payload) {
            let clientPayLoad = response.payload;
            if (clientPayLoad.Status=="SUCCESS") {
              let serverPayload = clientPayLoad.payload;
              if (isValid(serverPayload.app_value)) {
                setMapsApiKey(serverPayload.app_value);
              }
            }
          }
        })
      }
    },[isValidServer, server]);

    const stopInterval = (interval) => {
      clearInterval(interval);
     };

    useEffect(() => {
      if (isValidServer) {
        fetchPosition(0);
        const interval = setInterval(() => {
            fetchPosition(interval);
            //For Debugging
            console.log("hitting api on every 10 sec");
        }, 10000);
        return () => stopInterval(interval);
      }
      else {
        updateTrackingDetails({"Status": FAILURE_STATUS, "Message": "Invalid url! Server parameter missing"})
      }
    }, []);

    let collapseItems = [
      {
        key: '1',
        label: <span style= {{fontWeight: 'bold'}}>Vehicle Details</span>,
        children: <VehicleInfo trackingDetails={trackingDetails} />,
      },
      {
        key: '2',
        label: <span style= {{fontWeight: 'bold'}}>Locate In Map</span>,
        children: isValid(mapApiKey)?<LiveMap trackingDetails={trackingDetails} mapApiKey={mapApiKey}/>:null,
      },
    ];

    return (
        <div className="banner-background">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="tab-container">
                <div className="app-title">Track your vehicle</div>
                {isMobile?<Collapse items={collapseItems} bordered={false} defaultActiveKey={[activeKey]} />:
                  <Tabs activeKey = {activeKey} onChange={onChangeTab}>
                  <TabPane tab={<span><InfoCircleOutlined /> Vehicle Details </span>} key="1">
                      <VehicleInfo trackingDetails={trackingDetails} />
                  </TabPane>
                  <TabPane tab={<span><RadarChartOutlined /> Locate In Map </span>} key="2">
                    {isValid(mapApiKey)&&<LiveMap trackingDetails={trackingDetails} mapApiKey={mapApiKey}/>}
                  </TabPane>
                </Tabs>}
              </div>
            </Col>
          </Row>
        </div>
    );
};

TrackingTab.defaultProps = {

};

TrackingTab.propTypes = {

};
