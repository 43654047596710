import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import './live-map.component.scss';
import {FAILURE_STATUS, SUCCESS_STATUS} from "../../utils/constant";
import { isValidArray, isValid } from '../../utils/utilities';
import { DeviceContext } from './../device-provider/device-provider';
let _ = require('underscore');


const LiveMap = props => {

  const {trackingDetails, mapApiKey} = props;

  const {
    isMobile
  }=useContext(DeviceContext);

  let position = {};

  if (trackingDetails !== undefined && trackingDetails.Status === SUCCESS_STATUS) {
    if (isValid(trackingDetails.payload)&&isValid(trackingDetails.payload.tracking)&&isValid(trackingDetails.payload.tracking.latitude)) {
      position.lat = trackingDetails.payload.tracking.latitude;
      position.long = trackingDetails.payload.tracking.longitude;
    }
    else {
      return (
          <div className="map-message">
              <h5>{isValid(trackingDetails.payload.message)?trackingDetails.payload.message:"Tracking data not available. Please try again later!"}</h5>
          </div>
      );
    }
  }

  if (trackingDetails !== undefined && trackingDetails.Status === FAILURE_STATUS) {
      return (
          <div className="map-message">
              <h5> {trackingDetails.Message}</h5>
          </div>
      );
  }

  let containerStyle = {
    width: '100%',
    height: '500'
  };

  // if(isMobile){
  //   containerStyle['width']='300';
  //   containerStyle['height']='200';
  // }

  return (
      <div>
        {isValidArray(position)&&isValid(position.lat)&&isValid(position.long)&&
        <iframe
                seamless
                defer
                width={containerStyle.width}
                height={containerStyle.height}
                style={{border:0}}
                frameborder="0"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=${mapApiKey}&q=${position.lat},${position.long}`}>
        </iframe>
        }
      </div>
  );
};

function areEqual(prevProps, nextProps) {
    if (prevProps.trackingDetails && nextProps.trackingDetails && prevProps.trackingDetails.payload && nextProps.trackingDetails.payload && prevProps.mapApiKey && nextProps.mapApiKey) {
        return !!(_.isEqual(prevProps.trackingDetails.payload.tracking.latitude, nextProps.trackingDetails.payload.tracking.latitude)
            && _.isEqual(prevProps.trackingDetails.payload.tracking.longitude, nextProps.trackingDetails.payload.tracking.longitude));
    } else {
        return false;
    }
}

LiveMap.defaultProps = {

};

LiveMap.propTypes = {
  trackingDetails: PropTypes.object
};

export default React.memo(LiveMap, areEqual);
