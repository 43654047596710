import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import "./index.css";
import { ROUTES } from './routes/routes'

const router = createBrowserRouter(ROUTES);
const App = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default App;