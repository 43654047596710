/**
 * @description Routes, which can by react router in client side
 * and in server side through static router
 */

/**
 *  A sample route is 
 *  {
 *      @property path : String - The URL Path to be matched for
 *      @property component: ReactComponent - The component that needs to be rendered
 *      | Optional props |
 *      @property exact: Boolean - If true looks only for exact matches of routes
 * 
 *      | Below properties are applicable only in server side rendering | * 
 *      @property isSSR: Boolean - If true the route will be rendered in server side.
 *      @property isCacheable: Boolean - If true would enable cache for the components.
 *      @property loadData: Function - If the component needs to load data before render
 *                          It will be called as loadData(store,req)
 *                              Store - The redux store object
 *                              req - The request object
 *                The function must be responsible for dispatching the needed actions to the store
 *      @property initSaga: function* - The redux saga that will be run on the middleware
 *  }
 */

import { HomePage } from "../pages/home.page";
import {CustomFooter} from './../assets/ui-components/custom-footer/custom-footer.component';
import { Layout } from "antd";

function MainLayout(Component) {
    return (
        <Layout>
            <Component />
            <CustomFooter />
        </Layout>
    );
}

export const ROUTES = [
    {
        path: '/*',
        element: MainLayout(HomePage),
    }
];
