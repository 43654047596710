import React from 'react';
import * as PropTypes from 'prop-types';
import './custom-footer.component.scss';
import { Col, Layout, Row } from "antd";

const { Footer } = Layout;

export const CustomFooter = props => {
  return (
    <Footer>
      <Row gutter={{lg: 16, md: 10}}>
        <Col lg={8} md={10}>
          <div className="footer-title">
            Powered by QwikBus
          </div>
          <div className="footer-description">
            Tracks buses live and tells you what time your bus will reach your location.
          </div>
          <div className="copyright-info">
            © 2024 All Right Reserved.
          </div>
        </Col>
      </Row>
    </Footer>
  );
};

CustomFooter.defaultProps = {

};

CustomFooter.propTypes = {

};
